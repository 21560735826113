import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledLogin } from "./style";
import { useDispatch } from "react-redux";
import AdminLogo from "../../assests/img/edocs-logo.png";
import { authenticate } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
};

const loginSchema = yup.object({
  email: yup.string().email().required("Please enter your email"),
  password: yup.string().min(6).required("Please enter your password"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Master Admin Template Login";
  }, []);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(authenticate(values))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res?.message}`, {
              className: "toast-message",
            });
            navigate("/admin");
          } else if (res?.message) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
          }
        })
        .catch((err) => {
          if (err.status === 0 && err.message) {
            toast.error(`${err.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  return (
    <StyledLogin>
      <div className="container">
        <div className="col-lg-12  d-flex justify-content-center mt-5">
          <img src={AdminLogo} width="200" alt="AdminLogo" />
        </div>

        <div className="card card-login mx-auto mt-5 col-lg-5">
          <div className="card-header">Login</div>
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <div className="form-label-group">
                  <label htmlFor="inputEmail">Email address</label>
                  <input
                    type="email"
                    id="inputEmail"
                    name="email"
                    className="form-control"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus="autoFocus"
                  />
                  <p style={{ color: "red" }}>{errors.email}</p>
                </div>
              </div>
              <div className="form-group mt-3">
                <div className="form-label-group">
                  <label htmlFor="inputPassword">Password</label>
                  <input
                    type="password"
                    id="inputPassword"
                    name="password"
                    className="form-control"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <p style={{ color: "red" }}>{errors.password}</p>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block mt-4 w-100 submit-btn"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </StyledLogin>
  );
};

export default Login;
